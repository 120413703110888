import { absenCover, allenhealthCover, arthurholmCover, asteraCover, audinateCover, avidCover } from "./images";

export const BrandsData = [
    { 
        name: "Astera", 
        value: 'Astera',
        origin: "German",
        cover: asteraCover,
        category:"Hardware & Software",
        established: 'Mid 2000s',
        details:"Astera is a German hardware, software and manufacturing specialist. Since the mid-2000s, Astera has been producing battery-powered, remote controlled LED lights.	Astera is a key player in the film and event lighting industries and has a reputation as one of the most innovative companies in the business.",
        products: "LED Tubes, Pars and Bulbs, DMX Box and App"
    },
    { 
        name: "Audinate", 
        value: 'Audinate',
        origin: "German",
        cover: audinateCover,
        established: 'Mid 2000s',
        category:"Hardware & Software",
        details:"AV systems have traditionally required point-to-point physical connections between devices, resulting in cumbersome amounts of specialized, single-purpose cables that define where audio and video signals can go. Changes are labor-intensive and expensive, and noise and signal degradation are constant companions as distances grow. The result is systems that are difficult to deploy and even more difficult to adapt as demands shifts.",
        products: "LED Tubes, Pars and Bulbs, DMX Box and App"
    },
    { 
        name: "Arthur Holm", 
        value: 'Arthur Holm',
        origin: "German",
        cover: arthurholmCover,
        established: 'Mid 2000s',
        category:"Hardware & Software",
        details:"ARTHUR HOLM is shaping the boardroom of the future offering an inspiring product range providing superior materials with an innovative design, flexibility and customisation. Arthur Holm creates solutions to enhance communication, decision making and collaboration in reception areas and conference and meeting spaces.",
        products: "LED Tubes, Pars and Bulbs, DMX Box and App"
    },
    { 
        name: "Absen Led", 
        value: 'Absen Led',
        origin: "German",
        cover: absenCover,
        established: '2001',
        category:"Hardware & Software",
        details:"Established in 2001, Absen is the world's leading LED display brand, renowned for its high-quality products, global presence and full-service capabilities. From corporate lobbies and retail stores to outdoor advertising and live events, Absen has delivered more than 50,000 installations in over 130 countries.",
        products: "LED Tubes, Pars and Bulbs, DMX Box and App"
    },
    { 
        name: "Avid", 
        value: 'Avid',
        origin: "German",
        cover: avidCover,
        established: 'Mid 2000s',
        category:"Hardware & Software",
        details:"AVID specialises in audio and video; specifically, digital non-linear editing (NLE) systems, video editing software, audio editing software, music notation software, management and distribution services.",
        products: "LED Tubes, Pars and Bulbs, DMX Box and App"
    },
    { 
        name: "Allen & Health", 
        value: 'Allen & Health',
        origin: "German",
        cover: allenhealthCover,
        established: 'Mid 2000s',
        category:"Hardware & Software",
        details:"Allen & Heath is a leading designer and manufacturer of mixing desks for live sound, permanent installation, DJs, broadcast and recording studios.",
        products: "LED Tubes, Pars and Bulbs, DMX Box and App"
    },
  ];


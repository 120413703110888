import React from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../Breadcumb/Main'
import BGImg3 from '../../assets/images/shapes/service-four-bg-1-1.png'
import OwlCarousel from 'react-owl-carousel'

const WhatWeDo = () => {
    const options = {
        loop: false,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: false,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            576: {
                margin: 10,
                items: 2
            },
            768: {
                margin: 10,
                items: 3
            },
            992: {
                margin: 20,
                items: 3
            },
            1200: {
                margin: 20,
                items: 6
            }
        },
    };

  return (
    <>
    <Breadcumb Title="What We Do" Breadcumb="What We Do" />
    {/* <section className="section-padding--bottom section-padding--top">
			<div className="container">
				<div className="section-title text-center">
					<p className="section-title__text">What We Do</p>
					<h2 className="section-title__title">Our Expert Person to Provide <br /> IT Solution Services</h2>
				</div>
				<div className="row gutter-y-30">
					<div className="col-lg-4 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
							<div className="team-card-one__image">
								<img src="assets/images/team/team-1-1.jpg" alt="" />
								<div className="team-card-one__social">
									<ul className="team-card-one__social__links">
										<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										<li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
										<li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
										<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
									</ul>
									<div className="team-card-one__social__icon">
										<i className="fa fa-share-alt"></i>
									</div>
								</div>
							</div>
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><Link to="#">Michelle Monaghan</Link></h3>
								
								<p className="team-card-one__designation">Designer</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
							<div className="team-card-one__image">
								<img src="assets/images/team/team-1-2.jpg" alt="" />
								<div className="team-card-one__social">
									<ul className="team-card-one__social__links">
										<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										<li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
										<li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
										<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
									</ul>
									<div className="team-card-one__social__icon">
										<i className="fa fa-share-alt"></i>
									</div>
								</div>
							</div>
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><Link to="#">Jessica Brown</Link></h3>
								
								<p className="team-card-one__designation">Manager</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
							<div className="team-card-one__image">
								<img src="assets/images/team/team-1-3.jpg" alt="" />
								<div className="team-card-one__social">
									<ul className="team-card-one__social__links">
										<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										<li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
										<li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
										<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
									</ul>
									<div className="team-card-one__social__icon">
										<i className="fa fa-share-alt"></i>
									</div>
								</div>
							</div>
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><Link to="#">Kevin Martin</Link></h3>
								
								<p className="team-card-one__designation">Developer</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
							<div className="team-card-one__image">
								<img src="assets/images/team/team-1-4.jpg" alt="" />
								<div className="team-card-one__social">
									<ul className="team-card-one__social__links">
										<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										<li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
										<li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
										<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
									</ul>
									<div className="team-card-one__social__icon">
										<i className="fa fa-share-alt"></i>
									</div>
								</div>
							</div>
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><Link to="#">Sarah Albert</Link></h3>
								
								<p className="team-card-one__designation">Director</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
							<div className="team-card-one__image">
								<img src="assets/images/team/team-1-5.jpg" alt="" />
								<div className="team-card-one__social">
									<ul className="team-card-one__social__links">
										<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										<li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
										<li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
										<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
									</ul>
									<div className="team-card-one__social__icon">
										<i className="fa fa-share-alt"></i>
									</div>
								</div>
							</div>
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><Link to="#">Mike Hardson</Link></h3>
								
								<p className="team-card-one__designation">Designer</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">
							<div className="team-card-one__image">
								<img src="assets/images/team/team-1-6.jpg" alt="" />
								<div className="team-card-one__social">
									<ul className="team-card-one__social__links">
										<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										<li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
										<li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
										<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
									</ul>
									<div className="team-card-one__social__icon">
										<i className="fa fa-share-alt"></i>
									</div>
								</div>
							</div>
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><Link to="#">Christine Eve</Link></h3>
								
								<p className="team-card-one__designation">IT Expert</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> */}

		<section
                className=" section-padding--top service-four gray-bg section-padding-xl--bottom section-has-bottom-margin background-repeat-no background-position-top-right"
                style={{ backgroundImage: `url(${BGImg3})` }}>
                <div className="px-5">
                    <div className="section-title text-center">
                        <p className="section-title__text">Popular Services</p>
                        <h2 className="section-title__title">We Provide Our Client Best <br />IT Solution Services</h2>

                    </div>
                    <OwlCarousel
                        className="thm-owl__carousel thm-owl__carousel--with-shadow service-four__carousel"
                        {...options}
                    >
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-new-product"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title"><Link to="/infrastructure-plan">Managed Services</Link></h3>
                                    <div className="service-card-three__text">
										Monitoring<br/>
										Pro-Active Support<br/>
										Remote Support<br/>
										Schedule Maintenance<br/>
										Simplified Billing<br/>
										Centralized Management<br/>
										Reporting
                                    </div>
                                </div>
                            </div>
                        </div> 
						<div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-analysis"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title"><Link to="/infrastructure-plan">Process & Governance</Link></h3>
                                    <div className="service-card-three__text">
										Designing Policies<br/>
										Interoperability<br/>
										Sourcing<br/>
										Quality Assurance<br/>
										Operations<br/>
										Deliverables<br/>
										Documentation
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-protection"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title"><Link
                                        to="/infrastructure-plan">Information Security</Link></h3>
                                    <div className="service-card-three__text">Security Intelligence<br/>
										User Management<br/>
										Data Security<br/>
										System Hardening<br/>
										Assurance Services<br/>
										Security Governance<br/>
										Network & Data Security<br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-new-product"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title"><Link to="/infrastructure-plan">Various Consulting Services</Link></h3>
                                    <div className="service-card-three__text">
										Assesment<br/>
										Financial Analysis<br/>
										Solution Design<br/>
										Project Management<br/>
										Implementation<br/>
										Process Optimization<br/>
										Recalibration
                                    </div>
                                </div>
                            </div>
                        </div>
						<div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-web-development"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title"><Link to="/infrastructure-plan">Operation & Maintenance</Link></h3>
                                    <div className="service-card-three__text">
										Request Management<br/>
										Build & Release Management<br/>
										SLA (Multi-level Support)<br/>
										Defect Management<br/>
										Patch Development<br/>
										Performance Tuning
										{/* Platform Migration<br/> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-web-development"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title"><Link to="/infrastructure-plan">Security Awareness</Link></h3>
                                    <div className="service-card-three__text">
										Risk Assesment<br/>
										Policies & Procedures<br/>
										Business Case<br/>
										Design & Implementation<br/>
										Managed Services<br/>
										Deployment<br/>
										Awareness<br/>
										Training<br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
				{/* <div className="container">
					
				<OwlCarousel
                        className="thm-owl__carousel thm-owl__carousel--with-shadow service-four__carousel"
                        {...options}
                    >
						<div></div>
                    </OwlCarousel>
				</div> */}
		</section>

		{/* <section className="section-padding--bottom">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Popular Services</p>
            <h2 className="section-title__title">We Provide Our Client Best <br />IT Solution Services</h2>

          </div>
          <div className="row gutter-y-30">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <h3 className="service-card-two__title"><Link to="/service-01">Managed Services</Link></h3>

                <p className="service-card-two__text">
				Monitoring<br/>
				Pro-Active Support<br/>
				Remote Support<br/>
				Schedule Maintenance<br/>
				Simplified Billing<br/>
				Centralized Management<br/>
				Reporting
				</p>
                <div className="service-card-two__icon">
                  <i className="icon-smart-tv"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <h3 className="service-card-two__title"><Link to="/team">Process & Governance</Link></h3>

                <p className="service-card-two__text">
										Designing Policies<br/>
										Integration & Interoperability<br/>
										Sourcing<br/>
										Quality Assurance<br/>
										Operations<br/>
										Deliverables<br/>
										Documentation & Contents<br/>
                </p>
                <div className="service-card-two__icon">
                  <i className="icon-link"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <h3 className="service-card-two__title"><Link to="/contact">Information Security</Link></h3>

                <p className="service-card-two__text">Security Intelligence<br/>
										User Management<br/>
										Data Security<br/>
										System Hardening<br/>
										Assurance Services<br/>
										Security Governance<br/>
										Network & Data Security
                </p>
                <div className="service-card-two__icon">
                  <i className="icon-technical-support"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <h3 className="service-card-two__title"><Link to="/about">Consulting</Link></h3>

                <p className="service-card-two__text">
										Assesment<br/>
										Financial Analysis<br/>
										Solution Design<br/>
										Project Management<br/>
										Implementation<br/>
										Process Optimization<br/>
										Recalibration
                </p>
                <div className="service-card-two__icon">
                  <i className="icon-cctv"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <h3 className="service-card-two__title"><Link to="/about">Operation & Maintenance</Link></h3>

                <p className="service-card-two__text">
										Request Management<br/>
										Build & Release Management<br/>
										SLA (Multi-level Support)<br/>
										Defect Management<br/>
										Patch Development<br/>
										Performance Tuning<br/>
										Platform Migration
                </p>
                <div className="service-card-two__icon">
                  <i className="icon-cctv"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <h3 className="service-card-two__title"><Link to="/about">Security Awareness</Link></h3>

                <p className="service-card-two__text">
										Risk Assesment<br/>
										Policies & Procedures<br/>
										Business Case<br/>
										Design & Implementation<br/>
										Managed Services<br/>
										Deployment<br/>
										Awareness & Training<br/>
                </p>
                <div className="service-card-two__icon">
                  <i className="icon-cctv"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
        </>
  )
}

export default WhatWeDo
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import Breadcumb from '../Breadcumb/Main'
import { absenLogo, arthurholmCover, arthurholmLogo, asteraCover, audinateLogo, avBG, avSlider1, avSlider2, avidCover } from '../../assets/images'
import { BrandsData } from '../../assets/data'
import { 
	aestraLogo,
	allenhealthLogo,
	avidLogo, 
	extronLogo, 
	huddlyLogo, 
	jblLogo, 
	logitechLogo, 
	neatLogo, 
	rolandLogo, 
	samsungLogo,
   } from '../../assets/images';


const BrandPage = () => {
	const options = {
		loop: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		smartSpeed: 700,
		items: 1,
		margin: 0,
		nav: false,
		dots: false,
		responsive: {
			0: {
				items: 1,
				margin: 0
			},
			576: {
				items: 2,
				margin: 30
			},
			992: {
				items: 3,
				margin: 30
			}
		}
	}

	const logoOptions = {
		loop: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		nav: true,
		navText: ["<span class=\"fa fa-angle-left\"></span>", "<span class=\"fa fa-angle-right\"></span>"],
		dots: false,
		margin: 30,
		items: 2,
		smartSpeed: 700,
		responsive: {
		  0: {
			margin: 30,
			items: 2
		  },
		  375: {
			margin: 30,
			items: 2
		  },
		  575: {
			margin: 30,
			items: 3
		  },
		  767: {
			margin: 50,
			items: 4
		  },
		  991: {
			margin: 40,
			items: 5
		  },
		  1199: {
			margin: 80,
			items: 5
		  }
		}
	}

	const [brandData, setBrandData] = useState([
		{ 
			name: "Astera", 
			value: 'Astera',
			origin: "German",
			category:"Category",
			details:"Default Detail.",
			products: "Product",
			cover: asteraCover,
	}]);
	const [brandName, setBrandName] = useState('Astera');
	const [origin, setOrigin] = useState('German');
	const [category, setCategory] = useState('Hardware & Software');
	const [established, setEstablished] = useState('Mid 2000s');
	// const [products, setProducts] = useState('Mid 2000s');
	const [detail, setDetail] = useState('Default Details');

	// useEffect(() => {
	// 	// setCategory(brandName.);
	// // return () => {
	// // 	};
	//   }, [brandName]);

	  useEffect(() => {
		const filteredData = BrandsData.filter(brand => brand.name === brandName);
		setBrandData(filteredData);
		// setBrandName(brandData[0].name);
		// setOrigin(brandData[0].origin);
		// setCategory(brandData[0].category);
		// setDetail(brandData[0].details)
		console.log(brandData);
	  }, [brandName]);

	const handleButtonClick = (value) => {
	setBrandName(value);
	};
	return (
		<>
			<Breadcumb Title={brandData[0].name} Breadcumb="Audio - Video" Image={avSlider2} />
			<section className="project-details section-padding--bottom section-padding--top">
				<div className="container">
					<div className="project-details__image">
					<div className="client-carousel client-carousel--two">
						<div className="container">
						<OwlCarousel className="thm-owl__carousel" {...logoOptions}>
							{/* Audinate */}
							<div className="item">  
							<button
								onClick={() => handleButtonClick('Audinate')}
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							>
								<img src={audinateLogo} alt="Logitech" style={{ width: '100%', height: 'auto' }} />
							</button>
							</div>
							{/* Absen */}
							<div className="item">
							<button
								onClick={() => handleButtonClick('Absen Led')}
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							>
								<img src={absenLogo} alt="Absen" style={{ width: '100%', height: 'auto' }} />
							</button>
							</div>
							{/* Allen Health */}
							<div className="item">
							<button
								onClick={() => handleButtonClick('Allen & Health')}
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							>
								<img src={allenhealthLogo} alt="Allen & Health" style={{ width: '100%', height: 'auto' }} />
							</button>
							</div>
							{/* Avid */}
							<div className="item">
							<button
								onClick={() => handleButtonClick('Avid')}
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							>
								<img src={avidLogo} alt="Avid" style={{ width: '100%', height: 'auto' }} />
							</button>
							</div>
							{/* Astera */}
							<div className="item">
							<button
								onClick={() => handleButtonClick('Astera')}
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							>
								<img src={aestraLogo} alt="Aestra" style={{ width: '100%', height: 'auto' }} />
							</button>
							</div>
							{/* Arthur Holm */}
							<div className="item">
							<button
								onClick={() => handleButtonClick('Arthur Holm')}
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							>
								<img src={arthurholmLogo} alt="Arthur Holm" style={{ width: '100%', height: 'auto' }} />
							</button>
							</div>
							{/* <div className="item">
							<img src={huddlyLogo} alt="" />
							</div> */}
						</OwlCarousel>
						</div>
					</div>
						<img src={brandData[0].cover} alt="" />
					</div>
					<div className="project-details__info">
						<div className="project-details__info__item">
							<h4 className="project-details__info__title">Origin:</h4>
							<p className="project-details__info__text">{brandData[0].origin}</p>
						</div>

						<div className="project-details__info__item">
							<h4 className="project-details__info__title">Category:</h4>
							<p className="project-details__info__text"><Link to="#">{brandData[0].category}</Link></p>

						</div>
						<div className="project-details__info__item">
							<h4 className="project-details__info__title">Established:</h4>
							<p className="project-details__info__text">{brandData[0].established}</p>
						</div>
						{/* <div className="project-details__info__item">
							<h4 className="project-details__info__title">Value:</h4>
							<p className="project-details__info__text">87,000 USD</p>
						</div> */}
						<div className="project-details__info__item">
							<div className="project-details__info__social">
								<Link to="#"><i className="fab fa-twitter"></i></Link>
								<Link to="#"><i className="fab fa-facebook"></i></Link>
								<Link to="#"><i className="fab fa-instagram"></i></Link>
							</div>
						</div>
					</div>
					<h3 className="project-details__title">Manufacturing Specialist</h3>
					<div className="project-details__content">
						<p>{brandData[0].details}
                        </p>
						{/* <ul className="project-details__list">
						{brandData[0].products.map((product) =>
						<li>
							<i className="fa fa-check-circle"></i>
							{product.products}
						</li>
						)}
						</ul> */}
						<ul className="project-details__list">
							<li>
								<i className="fa fa-check-circle"></i>
								{brandData[0].products}
							</li>
							{/* <li>
								<i className="fa fa-check-circle"></i>
								LED Par and Bulbs.
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								DMX Box and App.
							</li> */}
						</ul>
					</div>
				</div>
			</section>

			<div className="project-nav">
				<div className="container">
					<div className="project-nav__inner">
						<Link to="#">
							<i className="icon-left-arrow"></i>
							Previous
						</Link>
						<Link to="#">
							Next
							<i className="icon-right-arrow"></i>
						</Link>
					</div>
				</div>
			</div>


			{/* <section className="section-padding--top section-padding--bottom">
				<div className="container">
					<div className="section-title text-center">
						<p className="section-title__text">similar projects</p>
						<h2 className="section-title__title">Check We Have Similar <br />
							IT Projects</h2>
					</div>

					<OwlCarousel className="thm-owl__carousel" {...options}>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-1.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-2.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-3.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-4.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-5.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-6.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</OwlCarousel>
				</div>
			</section> */}
		</>
	)
}

export default BrandPage
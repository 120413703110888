import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import Breadcumb from '../Breadcumb/Main'
import { Parallax } from 'react-parallax'
import BG from '../../assets/images/background/cta-two-bg-1-1.jpg'
import BG1 from '../../assets/images/background/cta-two-bg-1-2.png'
import { 
  aestraLogo,
  allenhealthLogo,
  avidLogo, 
  extronLogo, 
  huddlyLogo, 
  jblLogo, 
  logitechLogo, 
  neatLogo, 
  rolandLogo, 
  samsungLogo, 
  avBG, 
  avBG1,
  audioBG, 
  cableBG,
  lightningBG,
  videoBG,
  conferenceBG,
  acousticBG,
  communicationBG,
  avSlider1,
  avSlider2,
  asteraCover,
  arthurholmLogo,
  absenLogo,
  audinateLogo
 } from '../../assets/images';
import { useState } from 'react';
import { BrandsData } from '../../assets/data';
import { useEffect } from 'react';

const AudioVideo = () => {
  const logoOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: true,
    navText: ["<span class=\"fa fa-angle-left\"></span>", "<span class=\"fa fa-angle-right\"></span>"],
    dots: false,
    margin: 30,
    items: 2,
    smartSpeed: 700,
    responsive: {
      0: {
        margin: 30,
        items: 2
      },
      375: {
        margin: 30,
        items: 2
      },
      575: {
        margin: 30,
        items: 3
      },
      767: {
        margin: 50,
        items: 4
      },
      991: {
        margin: 40,
        items: 5
      },
      1199: {
        margin: 80,
        items: 5
      }
    }
  }
  const serviceOptions = {
    loop: false,
    nav: true,
    dots: false,
    items: 1,
    navText: ["<span class=\"fa fa-angle-left\"></span>", "<span class=\"fa fa-angle-right\"></span>"],
    margin: 0,
    responsive: {
      0: {
        items: 1,
        margin: 0
      },
      768: {
        items: 1,
        margin: 30
      },
      1200: {
        items: 2,
        margin: 30
      },
      1700: {
        items: 3,
        margin: 30
      }
    }
  }
  const sliderOptions = {
    loop: true,
    items: 1,
    navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
    margin: 0,
    dots: true,
    nav: true,
    animateOut: "slideOutDown",
    animateIn: "fadeIn",
    smartSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplayHoverPause: false
}

  const [brandData, setBrandData] = useState([
  { 
    name: "Astera", 
    value: 'Astera',
    origin: "German",
    category:"Category",
    details:"Default Detail.",
    products: "Product",
    cover: asteraCover,
  }]);
const [brandName, setBrandName] = useState('Astera');
useEffect(() => {
  const filteredData = BrandsData.filter(brand => brand.name === brandName);
  setBrandData(filteredData);
  // setBrandName(brandData[0].name);
  // setOrigin(brandData[0].origin);
  // setCategory(brandData[0].category);
  // setDetail(brandData[0].details)
  console.log(brandData);
  }, [brandName]);

const handleButtonClick = (value) => {
setBrandName(value);
};

  return (
    <> 
         {/* <div className="slider-one">
                <OwlCarousel className="slider-one__carousel owl-theme thm-owl__carousel" {...sliderOptions}>
                    <div className="item slider-one__slide-1">
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${avSlider1})` }}>
                        </div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">
                                <p className="slider-one__text">Welcome to</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">Burraq Integrated<br />
                                        Solutions</h2>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/about" className="thm-btn slider-one__btn"><span>Learn More</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item slider-one__slide-2">
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${slider2})` }}>
                        </div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">

                                <p className="slider-one__text">Welcome to</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">Burraq Integrated <br />
                                    Solutions</h2>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/about" className="thm-btn slider-one__btn"><span>Learn More</span></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item slider-one__slide-3">
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${slider4})` }}>
                        </div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">
                                <p className="slider-one__text">Welcome to</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">Burraq Integrated <br />
                                    Solutions</h2>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/about" className="thm-btn slider-one__btn"><span>Learn More</span></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </div> */}
      <Breadcumb Title="Audio - Video" Breadcumb="Audio - Video" Image={avSlider2}/>

      <section className="section-padding--top section-padding--bottom about-one">
        <div className="container">
          <div className="row gutter-y-60">
            <div className="col-lg-6">
              <div className="about-one__image">
                <img src={avBG} className="wow fadeInUp"
                  data-wow-duration="1500ms" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-one__content">
                <div className="section-title ">
                  <p className="section-title__text">About Company</p>
                  <h2 className="section-title__title">Creating Optimal Experiences to Display a Better World</h2>
                </div>
                <div className="about-one__text">
                    Burraq, with its reputable brands, skilled team, and solid technical foundation, has emerged as a market leader in its niche industry. 
                    Renowned consultants, system integrators, and end-users trust Burraq, which has successfully assisted top-tier clients with seamless integration and deployment.
                </div>
                <ul className="about-one__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Engaging audio and video: Delivering high-quality AV experiences from broadroom to large-scale events.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Indoor and outdoor capabilities: Excelling in all environments, we create memorable experiences that endure.
                  </li>
                  {/* <li>
                    <i className="fa fa-check-circle"></i>
                    Indoor and outdoor capabilities: Excelling in all environments.
                  </li> */}
                </ul>
                <Link to="/about" className="thm-btn about-one__btn"><span>Learn More</span></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      

      {/* Old Brands */}
      {/* <div className="client-carousel client-carousel--two">
        <div className="container">
          <OwlCarousel className="thm-owl__carousel" {...logoOptions}>
            <div className="item">
              <img src={logitechLogo} alt="" />
            </div>
            <div className="item">
              <img src={jblLogo} alt="" />
            </div>
            <div className="item">
              <img src={neatLogo} alt="" />
            </div>
            <div className="item">
              <img src={aestraLogo} alt="" />
            </div>
            <div className="item">
              <img src={extronLogo} alt="" />
            </div>
            <div className="item">
              <img src={huddlyLogo} alt="" />
            </div>
            <div className="item">
              <img src={rolandLogo} alt="" />
            </div>
            <div className="item">
              <img src={samsungLogo} alt="" />
            </div>
            <div className="item">
              <img src={allenhealthLogo} alt="" />
            </div>
            <div className="item">
              <img src={avidLogo} alt="" />
            </div>
          </OwlCarousel>
        </div>
      </div> */}

<section className="project-details section-padding--bottom section-padding--top">
				<div className="container">
					<div className="project-details__image">
					<div className="client-carousel client-carousel--two">
						<div className="container">
						<OwlCarousel className="thm-owl__carousel" {...logoOptions}>
							{/* Audinate */}
							<div className="item">  
							<button
								onClick={() => handleButtonClick('Audinate')}
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							>
								<img src={audinateLogo} alt="Logitech" style={{ width: '100%', height: 'auto' }} />
							</button>
							</div>
							{/* Absen */}
							<div className="item">
							<button
								onClick={() => handleButtonClick('Absen Led')}
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							>
								<img src={absenLogo} alt="Absen" style={{ width: '100%', height: 'auto' }} />
							</button>
							</div>
							{/* Allen Health */}
							<div className="item">
							<button
								onClick={() => handleButtonClick('Allen & Health')}
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							>
								<img src={allenhealthLogo} alt="Allen & Health" style={{ width: '100%', height: 'auto' }} />
							</button>
							</div>
							{/* Avid */}
							<div className="item">
							<button
								onClick={() => handleButtonClick('Avid')}
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							>
								<img src={avidLogo} alt="Avid" style={{ width: '100%', height: 'auto' }} />
							</button>
							</div>
							{/* Astera */}
							<div className="item">
							{/* <button
								onClick = {() => 
                  handleButtonClick('Astera')
                }
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							> */}
                <Link to="/brand-page">
                  <button>

                  <img src={aestraLogo} alt="Aestra" style={{ width: '100%', height: 'auto' }} />
                  </button>
                </Link>
							{/* </button> */}
							</div>
							{/* Arthur Holm */}
							<div className="item">
							<button
								onClick={() => handleButtonClick('Arthur Holm')}
								style={{ border: 'none', padding: 0, background: 'none', cursor: 'pointer' }}
							>
								<img src={arthurholmLogo} alt="Arthur Holm" style={{ width: '100%', height: 'auto' }} />
							</button>
							</div>
							{/* <div className="item">
							<img src={huddlyLogo} alt="" />
							</div> */}
						</OwlCarousel>
						</div>
					</div>
						<img src={brandData[0].cover} alt="" />
					</div>
					<div className="project-details__info">
						<div className="project-details__info__item">
							<h4 className="project-details__info__title">Origin:</h4>
							<p className="project-details__info__text">{brandData[0].origin}</p>
						</div>

						<div className="project-details__info__item">
							<h4 className="project-details__info__title">Category:</h4>
							<p className="project-details__info__text"><Link to="#">{brandData[0].category}</Link></p>

						</div>
						<div className="project-details__info__item">
							<h4 className="project-details__info__title">Established:</h4>
							<p className="project-details__info__text">{brandData[0].established}</p>
						</div>
						{/* <div className="project-details__info__item">
							<h4 className="project-details__info__title">Value:</h4>
							<p className="project-details__info__text">87,000 USD</p>
						</div> */}
						<div className="project-details__info__item">
							<div className="project-details__info__social">
								<Link to="#"><i className="fab fa-twitter"></i></Link>
								<Link to="#"><i className="fab fa-facebook"></i></Link>
								<Link to="#"><i className="fab fa-instagram"></i></Link>
							</div>
						</div>
					</div>
					<h3 className="project-details__title">Manufacturing Specialist</h3>
					<div className="project-details__content">
						<p>{brandData[0].details}
                        </p>
						{/* <ul className="project-details__list">
						{brandData[0].products.map((product) =>
						<li>
							<i className="fa fa-check-circle"></i>
							{product.products}
						</li>
						)}
						</ul> */}
						<ul className="project-details__list">
							<li>
								<i className="fa fa-check-circle"></i>
								{brandData[0].products}
							</li>
							{/* <li>
								<i className="fa fa-check-circle"></i>
								LED Par and Bulbs.
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								DMX Box and App.
							</li> */}
						</ul>
					</div>
				</div>
			</section>


      {/* <section className="black-bg section-padding-lg--top section-padding-lg--bottom cta-two">
        <div className="cta-two__bg jarallax" data-jarallax data-speed="0.2" data-imgPosition="50% 0%"
          style={{ backgroundImage: `url(${avBG1})` }}></div>
        <div className="container">
          <div className="cta-two__inner">
            <h3 className="cta-two__title">Better AV Solutions And Services
              At Your <span>Fingertips</span></h3>
            <Link to="/contact" className="thm-btn cta-two__btn"><span>LEarn More</span></Link>
          </div>
        </div>
      </section> */}
            <section class="black-bg cta-two">
                <Parallax bgImage={BG} bgImageAlt="" className="section-padding-lg--top section-padding-lg--bottom" strength={1000}>
                    <div className="container">
                        <div className="cta-two__inner">
                            <h3 className="cta-two__title">Better IT Solutions And Services
                                At Your <span>Fingertips</span></h3>
                            <Link to="/contact" className="thm-btn cta-two__btn"><span>Learn More</span></Link>
                        </div>
                    </div>
                </Parallax>
            </section>




      <section className="section-padding--bottom section-padding--top service-one">
        <div className="container-fluid">

          <OwlCarousel className="thm-owl__carousel service-one__carousel" {...serviceOptions}>
            <div className="item">
              <div className="service-card-one gray-bg">
                <div className="service-card-one__image">
                  <img src={audioBG} alt="" />
                  {/* <div className="service-card-one__logo">G</div> */}
                </div>
                <div className="service-card-one__content">
                  <h3 className="service-card-one__title"><Link to="cyber-security">Audio</Link></h3>
                  <p className="service-card-one__text">Lorem Ipsum is dummy text of the new design printng
                    and type setting Ipsum has been the is industrys.</p>

                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-one gray-bg">
                <div className="service-card-one__image">
                  <img src={videoBG} alt="" />
                  {/* <div className="service-card-one__logo">B</div> */}
                </div>
                <div className="service-card-one__content">
                  <h3 className="service-card-one__title"><Link to="cyber-security">Video</Link></h3>
                  <p className="service-card-one__text">Lorem Ipsum is dummy text of the new design printng
                    and type setting Ipsum has been the is industrys.</p>

                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-one gray-bg">
                <div className="service-card-one__image">
                  <img src={lightningBG} alt="" />
                  {/* <div className="service-card-one__logo">T</div> */}
                </div>
                <div className="service-card-one__content">
                  <h3 className="service-card-one__title"><Link to="cyber-security">Lightning</Link></h3>
                  <p className="service-card-one__text">Lorem Ipsum is dummy text of the new design printng
                    and type setting Ipsum has been the is industrys.</p>

                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-one gray-bg">
                <div className="service-card-one__image">
                  <img src={conferenceBG} alt="" />
                  {/* <div className="service-card-one__logo">T</div> */}
                </div>
                <div className="service-card-one__content">
                  <h3 className="service-card-one__title"><Link to="cyber-security">Conference, Boardroom and Event Halls</Link></h3>
                  <p className="service-card-one__text">Lorem Ipsum is dummy text of the new design printng
                    and type setting Ipsum has been the is industrys.</p>

                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-one gray-bg">
                <div className="service-card-one__image">
                  <img src={cableBG} alt="" />
                  {/* <div className="service-card-one__logo">T</div> */}
                </div>
                <div className="service-card-one__content">
                  <h3 className="service-card-one__title"><Link to="cyber-security">Cables and Infrastructure</Link></h3>
                  <p className="service-card-one__text">Lorem Ipsum is dummy text of the new design printng
                    and type setting Ipsum has been the is industrys.</p>

                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-one gray-bg">
                <div className="service-card-one__image">
                  <img src={acousticBG} alt="" />
                  {/* <div className="service-card-one__logo">T</div> */}
                </div>
                <div className="service-card-one__content">
                  <h3 className="service-card-one__title"><Link to="cyber-security">Acoustics</Link></h3>
                  <p className="service-card-one__text">Lorem Ipsum is dummy text of the new design printng
                    and type setting Ipsum has been the is industrys.</p>

                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-one gray-bg">
                <div className="service-card-one__image">
                  <img src={communicationBG} alt="" />
                  {/* <div className="service-card-one__logo">T</div> */}
                </div>
                <div className="service-card-one__content">
                  <h3 className="service-card-one__title"><Link to="cyber-security">Communication</Link></h3>
                  <p className="service-card-one__text">Lorem Ipsum is dummy text of the new design printng
                    and type setting Ipsum has been the is industrys.</p>

                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

            {/* Brands Section */}
            {/* <div className="client-carousel client-carousel--two">
                <div className="container">
                    <OwlCarousel className="thm-owl__carousel" {...logoOptions}>
                        <div className="item">
                            <img src={aestraLogo} alt="" />
                        </div>
                        <div className="item">
                            <img src={jblLogo} alt="" />
                        </div>
                        <div className="item">
                            <img src={allenhealthLogo} alt="" />
                        </div>
                        <div className="item">
                            <img src={avidLogo} alt="" />
                        </div>
                        <div className="item">
                            <img src={extronLogo} alt="" />
                        </div>
                        <div className="item">
                            <img src={huddlyLogo} alt="" />
                        </div>
                        <div className="item">
                            <img src={neatLogo} alt="" />
                        </div>
                        <div className="item">
                            <img src={logitechLogo} alt="" />
                        </div>
                        <div className="item">
                            <img src={rolandLogo} alt="" />
                        </div>
                        <div className="item">
                            <img src={samsungLogo} alt="" />
                        </div>
                    </OwlCarousel>
                </div>
            </div> */}



      <section className="section-padding--bottom">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Company Benefits</p>
            <h2 className="section-title__title">We Provide Comprehensive Integrated & Scalable Audio and Video Solutions<br />in Collabration with Top Brands</h2>

          </div>
          <div className="row gutter-y-30">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <h3 className="service-card-two__title"><Link to="/service-01">Design</Link></h3>

                <p className="service-card-two__text">We design Turnkey Solutions
                </p>
                <div className="service-card-two__icon">
                  <i className="icon-smart-tv"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <h3 className="service-card-two__title"><Link to="/team">Expert Team</Link></h3>

                <p className="service-card-two__text">Helps in Analysis & Deployment
                </p>
                <div className="service-card-two__icon">
                  <i className="icon-link"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <h3 className="service-card-two__title"><Link to="/contact">Excellent Support</Link></h3>

                <p className="service-card-two__text">Ensuring the Operations Smoothly
                </p>
                <div className="service-card-two__icon">
                  <i className="icon-technical-support"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <h3 className="service-card-two__title"><Link to="/about">Management</Link></h3>

                <p className="service-card-two__text">Optimize the Project Schedule & Cost
                </p>
                <div className="service-card-two__icon">
                  <i className="icon-cctv"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-one">
        <div className="container">
          <div className="cta-one__inner text-center wow fadeInUp" data-wow-duration="1500ms">
            <div className="cta-one__circle"></div>
            <div className="section-title ">
              <p className="section-title__text">Need Any Technology Solution</p>
              <h2 className="section-title__title section-title__title--light">Let’s Work Together on Project</h2>
            </div>
            <Link to="/contact" className="thm-btn thm-btn--light cta-one__btn"><span>Start Now</span></Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default AudioVideo